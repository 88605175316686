import * as Sentry from '@sentry/vue';
import { v4 as uuidv4 } from 'uuid';
import useAxios from './useAxios';
import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';
import { getDevice } from '@/src/hooks/useDevice';
import { isSsr } from '@/src/utilities/Utilities';
import { getCookie, setCookie } from '@/src/utilities/CookieHelpers';
import { parseQueryString } from '@/src/utilities/Url';
import { CookieCategory, waitForCookieAccess } from '@/src/services/cookieConsent';
import { useAppConfig } from '@/src/config';

interface AnalyticsQuery {
  cmpid: number;
  tz: string;
  pid?: number;
  sid?: string;
  abid?: number;
  ref?: string;
  rid?: number;
  usour?: string;
  umedi?: string;
  ucamp?: string;
  uterm?: string;
  ucont?: string;
  d?: string | null; // Device
}

interface AnalyticsResponse {
  sid?: string;
}

let sessionId: string | null = null;
let sessionLastSeen: number | null = null;
const tracked: number[] = [];

const cleanseValueForRequest = (value?: string) => {
  return value ? value.replace(/[^a-zA-Z0-9-_+% ]/g, '') : undefined;
};

const cleanseUrlForRequest = (url?: string) => {
  if (!url) {
    return undefined;
  }

  const urlObject = new URL(url);
  const queryParameters = parseQueryString(url);

  // Reconstruct the query string with the cleansed values.
  const cleansedQueryString = Object.keys(queryParameters)
    .map((key) => `${key}=${encodeURIComponent(cleanseValueForRequest(queryParameters[`${key}`]) ?? '')}`)
    .join('&');

  // Reconstruct the URL with the cleansed query string.
  return `${urlObject.origin}${urlObject.pathname}${cleansedQueryString ? '?' + cleansedQueryString : ''}`;
};

export default function useAnalytics() {
  const campaignStore = useCampaignStore();
  const utilityStore = useUtilityStore();
  const appConfig = useAppConfig();

  const cookiePrefix = `${campaignStore.model?.id}-sid`;
  const sessionTimeout = 60 * 5;
  const sessionTimeoutInSession = 60 * 5;

  sessionLastSeen = sessionLastSeen ?? getCookie(`${cookiePrefix}-seen`) ?? null;
  sessionId = sessionId ?? getCookie(cookiePrefix) ?? uuidv4();

  if (!(sessionLastSeen && Math.floor((new Date().getTime() - sessionLastSeen) / 1000) < sessionTimeout)) {
    sessionId = uuidv4();
  }

  const handleSaveSession = async () => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 5 * 60 * 1000);
    const cookieOptions = { expire: expires };
    sessionLastSeen = new Date().getTime();
    await waitForCookieAccess(CookieCategory.STATISTICS);
    setCookie(cookiePrefix, sessionId ?? '', cookieOptions, true);
    setCookie(cookiePrefix + '-seen', sessionLastSeen ? sessionLastSeen.toString() : '', cookieOptions, true);
  };
  const isDemo = () => {
    return utilityStore.url.includes('/campaign/view/demo');
  };

  const handlePageView = async (pageId?: number) => {
    if (
      isSsr() ||
      (pageId && tracked.includes(pageId)) ||
      campaignStore.model?.state?.config?.expired ||
      !campaignStore.model?.state.config?.enableStatistics
    ) {
      return;
    }

    if (!(sessionLastSeen && Math.floor((new Date().getTime() - sessionLastSeen) / 1000) < sessionTimeoutInSession)) {
      sessionId = uuidv4();
    }

    if (pageId) {
      tracked.push(pageId);
    }

    if (!isDemo() && campaignStore.model?.state.config?.customerId && campaignStore.model.id) {
      const registrationId = campaignStore.flowRegistrationInfo?.id;
      const docReferrer = document.referrer;
      const queryParams = parseQueryString(utilityStore.url);

      const utmSource = queryParams.utm_source;
      const utmMedium = queryParams.utm_medium;
      const utmCampaign = queryParams.utm_campaign;
      const utmTerm = queryParams.utm_term;
      const utmContent = queryParams.utm_content;

      // Post to the old analytics platform
      if (campaignStore.model.state.config.analytics?.basePath) {
        const query: AnalyticsQuery = {
          cmpid: campaignStore.model.id,
          tz: campaignStore.model.state?.config?.timezone,
          pid: pageId
        };

        query.sid = String(sessionId);

        // Exclude referrer on popup
        if (docReferrer && !['popup', 'popupv2'].includes(campaignStore.model.state?.config.gameAlias)) {
          // Exclude query string from referrer
          query.ref = (docReferrer + '').split('?')[0];
        }

        if (registrationId) {
          query.rid = registrationId;
        }

        if (utmSource) {
          query.usour = utmSource;
        }

        if (utmMedium) {
          query.umedi = utmMedium;
        }

        if (utmCampaign) {
          query.ucamp = utmCampaign;
        }

        if (utmTerm) {
          query.uterm = utmTerm;
        }

        if (utmContent) {
          query.ucont = utmContent;
        }

        query.d = getDevice()[0];

        const { fetchData: postOldAnalytics } = useAxios<AnalyticsResponse>(
          `${campaignStore.model.state?.config.analytics.basePath}/api/v1/campaign/analytic/page-view`,
          query
        );

        try {
          await postOldAnalytics();
        } catch (e) {
          Sentry.captureException(e);

          // eslint-disable-next-line no-console
          console.error(`Could not post old analytics, ${e}`);
        }
      }

      // Post to the new analytics platform
      if ('analyticsEndpoint' in appConfig && typeof appConfig.analyticsEndpoint === 'string') {
        const { postData: postNewAnalytics } = useAxios(appConfig.analyticsEndpoint, {
          type: 'CampaignPageView101',
          data: {
            campaign_id: campaignStore.model.id,
            customer_id: campaignStore.model.state.config.customerId,
            session_id: sessionId,
            page_id: pageId,
            registration_id: registrationId,
            url: cleanseUrlForRequest(utilityStore.url),

            // Only allow alphanumeric characters (a-z, A-Z, 0-9), along with the characters -, _, and +
            // This is to avoid ingestion errors due to inproper escapes strings with our Redshift loader
            utm_source: cleanseValueForRequest(utmSource),
            utm_medium: cleanseValueForRequest(utmMedium),
            utm_campaign: cleanseValueForRequest(utmCampaign),
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            device: getDevice(),
            referrer: cleanseUrlForRequest(docReferrer)
          }
        });

        try {
          await postNewAnalytics(false);
        } catch (e) {
          Sentry.captureException(e);

          // eslint-disable-next-line no-console
          console.error(`Could not post new analytics, ${e}`);
        }
      }

      handleSaveSession();
    }
  };

  return {
    handlePageView
  };
}
